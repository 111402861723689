import Logo from '../assets/images/dg_logo.png';
import 'bootstrap/js/dist/dropdown';

const Header = () => {
//   let scrolled = false;
  let anchor = 'Anchorform';
  const moveToAnchorHandle = (e) => {
    e.preventDefault();
    document.getElementById(anchor).scrollIntoView({ behavior: 'smooth' });
    // scrolled = window.scrollY > 0;
  };

  return (
    <>
      <nav className='navbar fixed-top navbar-expand-lg navbar-light'>
        <div className='container-sm'>
          <a className='navbar-brand' href='/'>
            <img height='70' src={Logo} alt='DigiCatt Media Logo' />
          </a>
          <button
            className='navbar-toggler bg-white'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNavDropdown'
            aria-controls='navbarNavDropdown'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNavDropdown'>
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <a href='/' className='nav-link'>
                  CLIENTES
                </a>
              </li>
              <li className='nav-item dropdown'>
                <a
                  href='/'
                  className='nav-link dropdown-toggle'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  PORTAFOLIO
                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                  <li>
                    <a className='dropdown-item' href='/'>
                      Web / Apps
                    </a>
                  </li>
                  <li>
                    <a className='dropdown-item' href='/'>
                      Diseño
                    </a>
                  </li>
                  <li>
                    <a className='dropdown-item' href='/'>
                      UI / UX
                    </a>
                  </li>
                </ul>
              </li>

              <li className='nav-item '>
                <button onClick={moveToAnchorHandle} className='btn nav-link'>
                  CONTACTO
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
