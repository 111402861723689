import Form from './Form';

const Home = () => {
  return (
    <>
      <div className='bg-dark'>
        {/* DESKTOP */}
        <div className='container d-none d-md-block'>
          <div className='row gx-0 justify-content-between'>
            <div className='col-md-5 align-self-center' style={{ marginTop: '6rem' }}>
              <p className='display-5 font2 fw-bold mb-4' style={{ lineHeight: '1.1' }}>
                seccion1.titulo_1
              </p>
              <button className='btn btn-primary text-white'>seccion1.parrafo_1</button>
            </div>
            <div className='col-md-6'>
              <img
                className='img-fluid'
                src='{{imgUrl + data.cover?.data.attributes.formats.medium.url}}'
                alt='imgUrl + data.cover?.data.attributes.formats.medium.url'
              ></img>
            </div>
          </div>
        </div>
        {/* MÓVIL */}
        <div className='container-fluid px-0 d-block d-md-none'>
          <div className='row gx-0 align-items-center' style={{ marginTop: '7rem' }}>
            <div className='col-6'>
              <img
                className='img-fluid'
                src='{{imgUrl + data.cover?.data.attributes.formats.small.url}}'
                alt='imgUrl + data.cover?.data.attributes.formats.small.url'
              ></img>
            </div>
            <div className='col-6'>
              <p className='h1 font2 fw-bold mb-2 ms-2' style={{ lineHeight: '1.1' }}>
                seccion1.titulo_1
              </p>
              <button className='btn btn-sm btn-primary text-white ms-2'>seccion1.parrafo_1</button>
            </div>
          </div>
        </div>
      </div>
      <Form></Form>
    </>
  );
};

export default Home;
